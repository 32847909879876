:root {
  --background-color: #{$background-color};
  --alt-background-color: #{$light-teal};
  --text-color: #{$text-color};
  --base-line-height: 1.75rem;
  --base-font-size: 16px;
  --small-font-size: 0.9375rem;
  --meta-font-size: 0.875rem;
}

@media print {
  :root {
    --background-color: #FFF;
    --alt-background-color: #FFF;
    --text-color: #000;
    --base-font-size: 12px;

    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

/**
 * Border-box by default.
 */
html {
  box-sizing: border-box;
  font-size: var(--base-font-size);
}
*, *:before, *:after {
  box-sizing: inherit;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/**
 * Basic styling
 */
body {
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  font-size: var(--base-font-size);
  line-height: var(--base-line-height);
  color: var(--text-color);
  background-color: var(--background-color);
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: calc(var(--base-line-height) / 2);
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: var(--small-font-size);
}



/**
 * Lists
 */
ul, ol {
  padding-left: $gutter;
}

ul {
  li::before {
    content: "•";
    display: inline-block;
    width: 0;
    position: absolute;
    left: -$gutter;
  }

  li {
    list-style-type: none;
    position: relative;
  }
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}



/**
 * Headings
 */
h1 {
  font-family: $page-head-font-family;
  font-weight: $page-head-font-weight;
  font-size: 1.75rem;
  line-height: 2.25rem;
  margin-bottom: calc(1 * var(--base-line-height));
  @media (min-height: 560px) {
    margin-bottom: calc(2 * var(--base-line-height));
  }
}

h2, h3, h4, h5, h6 {
  line-height: var(--base-line-height);
  font-family: $text-head-font-family;
  font-weight: $text-head-font-weight;
}

h2 {
  font-size: 1.25rem;
}

h3, h4, h5, h6 {
  font-size: var(--base-font-size);
}

/**
 * Links
 */
a {
  &:link {
    color: $link-color;
    text-decoration: none;
  }

  &:visited {
    color: $link-color;
  }

  &:hover {
    color: $link-color;
    text-decoration: underline;
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;

  position: relative;
  &:before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -($half-gutter + $line-width);
    border-left: 0.0625rem solid $grey-color;
    content: "";
  }

  font-size: var(--small-font-size);
  line-height: var(--base-line-height);
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }

  blockquote {
    margin-left: $half-gutter;
  }
}

/**
 * Sidenotes
 */
aside {
  color: $grey-color;
  line-height: var(--base-line-height);
  font-size: var(--small-font-size);
}


/**
 * Code formatting
 */
pre,
code {
  font-size: 0.9375rem;
  font-family: "Inconsolata", monospace;
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    fill: #{$grey-color};
    padding-right: 0.3125rem;
    vertical-align: text-top;
}


/**
 * Tables
 */

table {
  margin-top: 0;
  margin-bottom: calc(var(--base-line-height) / 2);
  width: 100%;
  text-align: $table-text-align;
  border-collapse: collapse;
  border-top: 2 * $line-width solid;
  border-bottom: 2 * $line-width solid;
  box-sizing: content-box;

  th, td {
    vertical-align: top;
  }

  th {
    border-bottom: $line-width solid;
    padding: (($spacing-unit / 4) - (2 * $line-width)) ($spacing-unit / 2);
  }
  td {
    padding: ($spacing-unit / 4) ($spacing-unit / 2);
    vertical-align: top;
  }
  th:first-child, td:first-child {
    padding-left: 0;
  }
  th:last-child, td:last-child {
    padding-right: 0;
  }
}

/**
 * Horizontal rule
 */

hr {
   height: var(--base-font-size);
   margin-top: var(--base-line-height);
   margin-bottom: calc((var(--base-line-height) - var(--base-font-size)) + (var(--base-line-height) / 2));
   background: url("/assets/asterism.svg") repeat-x 0 0;
   background-repeat: no-repeat;
   background-position: 50% 0;
   background-size: contain;
   border: 0;
}


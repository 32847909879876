$col-width-meta: 10.6875rem;
$col-width-footer: 18.0625rem;
$col-width-main: 30rem;
$col-width-aside: 18.0625rem;

$col-width-main-remainder: $col-width-main - $col-width-footer - $gutter;

$full-width:    $col-width-meta +
                $col-width-main +
                $col-width-aside +
                (4 * $gutter);

$mid-width:     $col-width-main +
                $col-width-aside +
                (3 * $gutter);

$on-mid-width:  $mid-width;
$on-full-width: $full-width;

// This technique works because of how SCSS (and CSS in general) handles unit
// math:
// - Multiplying by 0 preserves the unit but zeroes the value.
// - Adding 1 to 0 of any unit results in 1 of that unit.
// - Dividing by 1 of the same unit strips the unit.
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@mixin layout-width($width) {
  @media screen and (min-width: strip-unit($width) * 16px),
         print  and (min-width: strip-unit($width) * 12px) {
    @content;
  }
}

/**
 * Grid wrapper
 */
%grid-wrapper {
  display: grid;
  padding-left: $gutter;
  padding-right: $gutter;
  grid-template-columns:
    [meta-start main-start footer-start aside-start]
    3fr
    [nav-start]
    1fr
    [meta-end footer-end main-end aside-end nav-end];
  column-gap: $gutter;
  justify-content: center;

  @include layout-width ($on-mid-width) {
    grid-template-columns:
                                  [meta-start main-start footer-start]
      $col-width-footer           [footer-end]
      $col-width-main-remainder   [meta-end main-end aside-start nav-start]
      $col-width-aside            [aside-end nav-end];
  }

  @include layout-width ($on-full-width) {
    grid-template-columns:
                                  [meta-start]
      $col-width-meta             [meta-end main-start footer-start]
      $col-width-footer           [footer-end]
      $col-width-main-remainder   [main-end aside-start nav-start]
      $col-width-aside            [aside-end nav-end];
  }
}

// Bump up font size if possible
@media screen {
  @include layout-width ($on-full-width * (18 / 16)) {
    :root {
      --base-font-size: 18px;
    }
  }

  @include layout-width ($on-full-width * (20 / 16)) {
    :root {
      --base-font-size: 20px;
    }
  }
}
@media print {
  @include layout-width ($on-full-width * (14 / 12)) {
    :root {
      --base-font-size: 14px;
    }
  }

  @include layout-width ($on-full-width * (16 / 12)) {
    :root {
      --base-font-size: 16px;
    }
  }
}

/**
 * Site header
 */
.site-header {
  @extend %grid-wrapper;
  margin-top: var(--base-line-height);
  margin-bottom: calc(1 * var(--base-line-height));
  @media (min-height: 280px) {
    margin-bottom: calc(2 * var(--base-line-height));
  }
  @media (min-height: 560px) {
    margin-bottom: calc(5 * var(--base-line-height));
  }
}

.site-title {
  margin-top: 0.625rem;
}

.site-nav {
  grid-column: nav-start/nav-end;
  font-family: $meta-font-family;
  font-size: var(--meta-font-size);
  line-height: var(--base-line-height);

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li::before {
    content: "";
  }

  .page-link {
    color: $grey-color;
    line-height: var(--base-line-height);
  }
}



/**
 * Site footer
 */
.site-footer {
  @extend %grid-wrapper;
  padding-top: calc(2 * var(--base-line-height));
  padding-bottom: var(--base-line-height);
  background-color: var(--alt-background-color);
  p {
    grid-column: footer-start/footer-end;
    color: $grey-color;
    line-height: var(--base-line-height);
    font-size: var(--small-font-size);
  }
}




/**
 * Page content
 */
.page-content {
  padding: calc(1 * var(--base-line-height)) 0;
  @media (min-height: 560px) {
    padding: calc(2 * var(--base-line-height)) 0;
  }
  flex: 1;
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $spacing-unit;
  }
}

.meta-text,
.post-meta,
.link-meta {
  font-family: $meta-font-family;
  font-weight: $meta-font-weight;
  font-size: var(--meta-font-size);
  line-height: var(--base-line-height);
  color: $grey-color;
}

.post-link {
  display: block;
}



/**
 * Posts
 */
.home,
.archive,
.post {
  @extend %grid-wrapper;
}

.home,
.archive,
.post {
  .link-block,
  .page-header,
  .post-header,
  .section-header,
  .link-header {
    display: contents;
  }

  .page-title,
  .section-title,
  .link-title {
    grid-column: main-start/main-end;
  }

  .post-meta,
  .link-meta {
    grid-column: meta-start/meta-end;
    @include layout-width ($on-full-width) {
      text-align: right;
    }
  }

  .link-blurb {
    grid-column: main-start/main-end;
  }

  .section-content {
    grid-column: main-start/aside-end;
  }
}

* + .section-header > .section-title {
  margin-top: calc(2 * var(--base-line-height));
  @media (min-height: 560px) {
    margin-top: calc(3 * var(--base-line-height));
  }
}

.post,
.link-block.with-content {
  margin-bottom: calc(2 * var(--base-line-height));
}

.archive-block {
  grid-column: meta-start/aside-end;
  @extend %grid-wrapper;
  padding-left: 0;
  padding-right: 0;
  grid-auto-flow: row dense;
}

@include layout-width ($on-mid-width) {
  p, li {
    text-align: justify;
  }
}

@include layout-width ($on-mid-width) {
  .section-content > *, .post-excerpt > * {
    float: left;
    width: $col-width-main;
  }

  .section-content > aside {
    float: right;
    width: $col-width-aside;
  }
}

.section-content > p:not(.no-drop-cap):first-child:first-letter,
.section-content > hr + p:not(.no-drop-cap):first-letter {
  float: left;
  font-size: 3.75rem;
  line-height: 2.5rem;
  padding-top: 0.5625rem;
  padding-right: 0.1875rem;
  margin-left: -0.1875rem; /* -3px / 16px */
  padding-left: 0;
}


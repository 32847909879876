@charset "utf-8";

// Rtnl colours

$dark-teal: #041F1E;
$light-teal: #1B3C41;
$dark-red: #B13F35;
$light-red: #EA5448;
$beige: #F0E8D6;
$grey: #94938F;

// Define defaults for each variable.

@import url('https://fonts.googleapis.com/css2?family=Archivo&family=Spectral+SC:wght@300;500&family=Spectral:ital,wght@0,300;0,600;1,300;1,600&family=Inconsolata:wght@300&display=swap');

$base-font-family: 'Spectral', serif;
$base-font-weight: 300;

$meta-font-family: 'Spectral SC', serif;
$meta-font-weight: 500;

$page-head-font-family: 'Archivo', sans-serif;
$page-head-font-weight: 400;

$text-head-font-family: 'Spectral SC', serif;
$text-head-font-weight: 300;

$spacing-unit:     2rem !default;
$line-width:       0.0625rem !default;

$text-color:       $beige !default;
$background-color: $dark-teal !default;
$link-color:       $light-red !default;

$grey-color:       $grey !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

$gutter: 1.25rem;
$half-gutter: $gutter / 2;

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
